<template>
    <standard-page v-bind:title="catalogName">
        <template v-slot:breadcrumb>
            <span><router-link :to="{ name: 'identities-home'}">Identifier</router-link></span>
            <span><router-link :to="{ name: 'identities-catalogs'}">Catalogs</router-link></span>
            <span v-if="catalogName">CATALOG: {{catalogName | truncate(20, '.....')}}</span>
        </template>

        <template v-slot:content>
            <div class="az-content-label mg-b-5">Add Existing DID</div>
            <div class="mg-b-20" v-html="$DEFINITIONS.newCatalog"></div>
            <form @submit.prevent="handleSubmit">
                <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-100p">
                    <div class="row row-xs">
                        <div class="col-md-5">
                            <input :class="{'is-invalid': submitted && errors.has('did')}"
                                   class="form-control rounded"
                                   id="did"
                                   minlength="66"
                                   name="did"
                                   placeholder="Enter DID"
                                   v-model="addDidObj.did"
                                   v-validate="'required'">
                            <div class="invalid-feedback" v-if="submitted && errors.has('did')">{{ errors.first('did') }}</div>
                        </div>
                        <div class="col-md-5">
                            <input :class="{'is-invalid': submitted && errors.has('did_name')}"
                                   class="form-control rounded"
                                   id="did_name"
                                   name="did_name"
                                   placeholder="Enter DID Name"
                                   v-model="addDidObj.short_name"
                                   v-validate="'required'">
                            <div class="invalid-feedback" v-if="submitted && errors.has('did_name')">{{ errors.first('did_name') }}</div>
                        </div>
                        <div class="col-md mg-t-10 mg-md-t-0">
                            <button class="btn rounded btn-az-primary btn-block" type="submit">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </standard-page>
</template>

<script>
    import EventBus from "@/event-bus";

    export default {
        name: "AddExistingDid",
        props: ['catalogid'],
        data() {
            return {
                submitted: false,
                catalogName: null,
                addDidObj: {
                    did: null,
                    short_name: null
                },
            }
        },
        mounted() {
            this.getCatalog();
        },
        methods: {
            async getCatalog() {
                try {
                    let reply = await this.$identity.get(`/identity/catalogs/${this.catalogid}`);
                    this.catalogName = reply.data ? reply.data.name : null;
                } catch (e) {
                    this.$toastr.e("Error getting catalog name", "Error");
                }
            },
            handleSubmit(e) {
                this.submitted = true;
                this.$validator.validate()
                        .then(valid => {
                            if (valid) {
                                this.addDid();
                            }
                        });
            },
            async addDid() {
                try {
                    EventBus.$emit('openLoader');
                    if (this.addDidObj.did.indexOf('did:bws') > -1){
                        this.addDidObj.did = '0x'+ this.addDidObj.did.substring(8);
                    }
                        await this.$identity.patch(`identity/catalogs/${this.catalogid}/dids`, {add: [this.addDidObj]});
                    this.$toastr.s("DID Successfully added!", 'Success');
                    await this.$router.push({name: 'identities-catalogs-dids'});
                } catch (e) {
                    this.$toastr.e("DID addition failed", 'Error');
                } finally {
                    this.addDidObj.did = null;
                    this.addDidObj.short_name = null;
                    EventBus.$emit('closeLoader');
                }
            },
        }
    }
</script>

<style scoped>

</style>